<template>
  <ul class="pageList">
    <li class="pageList-item" v-for="p in pages" :key="p">
      <span v-if="!p">…</span>
      <span v-else-if="p === page">{{ p }}</span>
      <router-link v-else-if="to" :to="getRoute(p)">{{ p }}</router-link>
      <a href="#" v-else @click.prevent="$emit('update:page', p)">{{ p }}</a>
    </li>
  </ul>
</template>

<script>
import { computed } from 'vue'
export default {
  emits: ['update:page'],
  props: ['page', 'max', 'to'],
  setup (props) {
    const pages = computed(() => {
      const max = Math.max(props.max, 1)
      const arr = [...Array(7)].map((_, i) => i + props.page - 3).filter(v => v >= 1 && v <= max)
      if (!arr.includes(1)) {
        if (!arr.includes(2)) arr.unshift(null)
        arr.unshift(1)
      }
      if (!arr.includes(max)) {
        if (!arr.includes(max - 1)) arr.push(null)
        arr.push(max)
      }
      return arr
    })
    const getRoute = page => {
      if (!props.to) return
      const route = Object.assign({}, props.to)
      route.params = Object.assign({}, route.params, { page })
      return route
    }
    return {
      pages,
      getRoute
    }
  }
}
</script>

<style lang="scss" scoped>
.pageList {
  text-align: center;
  &-item {
    margin: 3px;
    display: inline-block;
    font-size: 13px;
  }
}
</style>
